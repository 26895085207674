import { OrgChart } from 'd3-org-chart';
import * as d3 from 'd3-selection';
import { HierarchyNode } from 'd3-hierarchy';

declare global {
  interface Window {
    ASSETS: { [key: string]: string }
  }
}

window.ASSETS = window.ASSETS || {};

type Family = {
  type: 'Family';
  internal_id: number;
  family_date_start: string | null;
  family_head_1: FamilyMember;
  family_head_2: FamilyMember;
}

type FamilyMember = {
  internal_id: number;
  type: 'FamilyMember';
  first_name: string;
  father_name: string;
  mother_name: string;
  birth_date: string | null;
  death_date: string | null;
  thumbnail: string | null;
}

type BaseTreeNode = {
  id: string;
  generation: number;
  parent_id?: string;
  _directSubordinates: number;
}

type FamilyNode = BaseTreeNode & Family;
type FamilyMemberNode = BaseTreeNode & FamilyMember;
type TreeNode = FamilyNode | FamilyMemberNode;

function isTreeNode(node: TreeNode | HierarchyNode<TreeNode>): node is TreeNode {
  return (<TreeNode>node).generation !== undefined;
}

const RING_COLORS = ["ring-bluish-500", "ring-bluish-400", "ring-bluish-300", "ring-bluish-200", "ring-bluish-100"]
const BG_COLORS = ["bg-bluish-500", "bg-bluish-400", "bg-bluish-300", "bg-bluish-200", "bg-bluish-100"]

const getColorForIndex = (index: number, type: 'ring' | 'background') => {
  if (type === 'ring') return RING_COLORS[index % RING_COLORS.length];
  return BG_COLORS[index % BG_COLORS.length];
}

const renderRibbonElement = (node: FamilyMember) => {
  // return node.death_date && window.ASSETS?.ribbon
  //   ? `<img class="w-6 absolute top-0" src="${window.ASSETS.ribbon}">`
  //   :  '';
  // TODO: Style ribbon accordingly. Currently its position is outside the container and on top of the whole web
  return '';
}

const loadData = async () => {
  const response = await fetch("/", {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  return await response.json() as Array<TreeNode>;
}

const renderFamilyNode = (node: FamilyNode) => {
  const bgColor = getColorForIndex(node.generation, 'background');
  const ringColor = getColorForIndex(node.generation, 'ring');

  return `
  <div class="pt-2 flex flex-row justify-between">
    <div class="max-w-[200px]" data-family-member="true" data-member-id="${node.family_head_1.internal_id}">
      <img class="block mx-auto h-16 w-16 rounded-full ring-8 ${ringColor}" src="${node.family_head_1.thumbnail}">
      ${renderRibbonElement(node.family_head_1)}
      <div class="flex rounded-xl -mt-8 pt-9 pb-1.5 px-4 ${bgColor}">
        <p class="self-center text-lg text-white text-center line-clamp-2">${node.family_head_1.first_name} ${node.family_head_1.father_name} ${node.family_head_1.mother_name}</p>
      </div>
    </div>
    <div class="max-w-[200px]" data-family-member="true" data-member-id="${node.family_head_2.internal_id}">
      <img class="block mx-auto h-16 w-16 rounded-full ring-8 ${ringColor}" src="${node.family_head_2.thumbnail}">
      ${renderRibbonElement(node.family_head_2)}
      <div class="flex rounded-xl -mt-8 pt-9 pb-1.5 px-4 ${bgColor}">
        <p class="self-center text-lg text-white text-center line-clamp-2">${node.family_head_2.first_name} ${node.family_head_2.father_name} ${node.family_head_2.mother_name}</p>
      </div>
    </div>
  </div>
  `
}

const renderFamilyMemberNode = (node: FamilyMemberNode) => {
  const bgColor = getColorForIndex(node.generation, 'background');
  const ringColor = getColorForIndex(node.generation, 'ring');

  return `
  <div data-family-member="true" data-member-id="${node.internal_id}">
    <img class="block mx-auto h-16 w-16 rounded-full ring-8 ${ringColor}" src="${node.thumbnail}">
    ${renderRibbonElement(node)}
    <div class="flex rounded-xl -mt-8 pt-9 pb-1.5 px-4 ${bgColor}">
      <p class="self-center text-lg text-white text-center line-clamp-2">${node.first_name} ${node.father_name} ${node.mother_name}</p>
    </div>
  </div>
  `
}

const drawChart = (data: Array<TreeNode>) => {
  return new OrgChart<TreeNode>()
    .container('#chart')
    .svgHeight(window.innerHeight - (document.querySelector('#header')?.clientHeight ?? 0))
    .data(data)
    .parentNodeId(node => isTreeNode(node) ? node.parent_id : node.data.parent_id)
    .buttonContent(({ node, state }) => {
      return `
        <div class="text-white w-6 h-6 rounded-[50%] text-center leading-[16.5px] p-1 m-auto ${getColorForIndex(node.data.generation, 'background')}">
          ${node.data._directSubordinates}
        </div>`;
    })
    .compactMarginBetween(node => 32)
    .nodeHeight(node => 150)
    .nodeWidth(node => {
      if (node.depth === 0) return 416;
      return 200;
    })
    .nodeContent((node) => {
      if (node.data.type === 'Family') {
        return renderFamilyNode(node.data);
      } else if (node.data.type === 'FamilyMember') {
        return renderFamilyMemberNode(node.data);
      }
      return "";
    })
    .linkUpdate(function (this: any) {
      d3.select(this)
        .attr('stroke', '#003051')
        .attr('stroke-width', 2);
    })
    .onNodeClick(d => window.location.href = `/members/${d}`)
    .defaultFont('Montserrat')
    .render();
};

document.addEventListener("DOMContentLoaded", async function () {
  const data = await loadData();
  const d3Chart = drawChart(data);
  d3Chart.zoomOut();

  const button = document.getElementById("expand-shrink-button") as HTMLElement;
  button.onclick = (e) => {
    const target = e.target as Element;
    if (target.textContent?.trim() === "Expandir") {
      target.textContent = "Encoger";
      d3Chart.expandAll();
    } else {
      target.textContent = "Expandir";
      d3Chart.collapseAll();
    };
  };
});
